import React, { useState, useRef, useContext, useEffect, useMemo } from "react";
import {
    Button,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    FormControl,
    Nav,
    Row,
    Tab,
} from "react-bootstrap";
import { Icon } from "@iconify/react";
import overflowMenuVertical from "@iconify-icons/carbon/overflow-menu-vertical";
import sendOutlined from "@iconify-icons/ant-design/send-outlined";
import paperclipIcon from "@iconify-icons/mdi/paperclip";
import ModalDommus from "../../../../../components/Modal";
import ReactTooltip from "react-tooltip";
import { errorToast } from "../../../../../components/DommusToast";
import convertFilesToBase64 from "../../../../../helpers/ConvertFilesToBase64";
import "./input.css";
import {isBrowser, MobileView, BrowserView, isMobile, isTablet} from 'react-device-detect';
import Upload from "./Upload/upload";
import { Galeria } from "../../../../../components/Galeria";
import Swal from "sweetalert2";
import { ChamadoContext } from "../../../../../components/ContextoGlobal";
import { cachearContexto } from "../../../../../helpers/cachearContexto";
import configuracaoService from "../../../../../services/ConfiguracaoService";
import { Editor } from "@tinymce/tinymce-react";
import { faBars, faPaperclip, faTextHeight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function Entrada({ onSendMessage }) {    
    const [mensagem, setMensagem] = useState("");
    const [editorTexto, setEditorTexto] = useState(false);  
    const [mensagemAnexos, setMensagemAnexos] = useState("");
    const [openModalUpload, setOpenModalUpload] = useState(false);
    const {limiteCaracteresNotaInterna} = useContext(ChamadoContext);
    const contextoChamados = useContext(ChamadoContext)
    const refInputMenssage = useRef();
    const refInputSection = useRef();

    useEffect(()=>{
        cachearContexto(contextoChamados.limiteCaracteresNotaInterna, contextoChamados.setLimiteCaracteresInternas, configuracaoService.buscarPorChave.bind(configuracaoService, 'limite_caracteres_notas_internas'));
    },[])

    const atualizarQuantidadeLinhasTextarea = () => {
        const textarea = refInputMenssage.current;
        textarea.rows = 1;
        const quantidadeLinhasTextearea = Math.ceil(
            textarea.scrollHeight /
                parseFloat(getComputedStyle(textarea).lineHeight)
        );
        textarea.rows =
            quantidadeLinhasTextearea >= 20 ? 20 : quantidadeLinhasTextearea - 1;
        if (textarea.rows >= 2) {
            textarea.style.borderTopLeftRadius = "10px";
            textarea.style.borderTopRightRadius = "10px";
        } else {
            textarea.style.borderTopLeftRadius = "0";
            textarea.style.borderTopRightRadius = "0";
        }
    };


    const onSubmit = () => {
        
        let mensagemInput = mensagem.trim() ?? null;
        if (mensagemInput && mensagemInput.length) {
            onSendMessage(mensagemInput);
            setMensagem("");
            setEditorTexto(false);
        } else {
            errorToast.fire({ text: "A mensagem não pode estar vazia." });
            setMensagem("");
        }
    };

    useEffect(()=>{
      if(!editorTexto){
        atualizarQuantidadeLinhasTextarea();
      }
    },[editorTexto, mensagem])

    
    const enviarArquivos = async(arquivos)=>{
        if(arquivos.length && mensagemAnexos.length){

            let filesConverter = [];
            let filesLink = [];
            arquivos.forEach((arquivo)=>{
                if(arquivo instanceof File){
                    filesConverter.push(arquivo);
                }else{
                    filesLink.push(arquivo);
                }
            })

            let arquivosConvertidos = await convertFilesToBase64.convertFileToBase64(
                filesConverter
            );
            onSendMessage(mensagemAnexos, [...arquivosConvertidos, ...filesLink]);   
            setMensagemAnexos("");
            setOpenModalUpload(false);                 
        }else{            
            Swal.fire({
                titleText: "Ooops...",
                text: (!arquivos.length ? "Nenhum arquivo para ser enviado!" : "") + (!mensagemAnexos.length ? "Campo de mensagem está vazio" : ""),
                icon: "warning",
            });
        }        
    }

    const DropdownOptions = ({className = 'btn-options', tamanho = 'sm'}) => {
        return (<DropdownButton
            className={className}
            id="dropMenuOpcoes"
            size={tamanho}
            title={<Icon icon={overflowMenuVertical} />}
        >
            <Dropdown.Item
                className="opcaoMenu"
                onClick={() => {
                    setOpenModalUpload(true);
                }}
            >
                <div className="item-menu opcao-enviar-arquivo">
                    <FontAwesomeIcon icon={faPaperclip} width="1.1rem" color="black"/>
                    <span className="tituloEnviarImagem">
                        Imagens & Anexos
                    </span>
                </div>
            </Dropdown.Item>
        </DropdownButton>)
    }

    const novasOpcoes = {
        language: "pt_BR",
        entity_encoding: "raw",
        selector: "textarea#default-editor",
        statusbar: false,
        menubar:false,
        height: 100,

        toolbar:
        "formatselect | bold italic",
        plugins: ['quickbars'],
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; border: none }",
        
      };

    const mceOpcoes = {
        selector: '.tinymce-body',
        menubar: false,
        plugins: [
          'link',
          'lists',
          'autolink',
          'quickbars'
        ],
        height: 300,
        toolbar: [
          'undo redo | bold italic underline'
        ],
        valid_elements: 'p[style],strong,em,span[style],a[href],ul,ol,li',
        valid_styles: {
          '*': 'font-size,font-family,color,text-decoration,text-align'
        },
      }   

    const btnOpenEditor = useMemo(()=>{
      return(
        <button
          className={`btn btn-open-editor mr-2 ${editorTexto ? 'ativo' : ''}`}
          data-tip={`${editorTexto ? "Fechar" : "Abrir"} editor de texto`}
          onClick={()=>{
            setEditorTexto((current) => !current);
          }}
        >
          <FontAwesomeIcon icon={faTextHeight}/>
        </button>
      )
    }, [editorTexto])

    return (
        <>
            <ReactTooltip />
            <div id="Input" ref={refInputSection}>
              {!editorTexto && btnOpenEditor }
              {!editorTexto && <DropdownOptions />}
              {editorTexto ?  
                <div className="editor-message-field">
                  <Editor
                      ref={refInputMenssage}
                      apiKey="25mzf46lh9blkhbpnxuvuz2etjw5cqrt3r82xl0geig8fhj5"
                      onEditorChange={(e) => {
                          if (e.length <= limiteCaracteresNotaInterna?.valor) {
                              setMensagem(e);
                          }
                      }} 
                      value={mensagem}
                      init={mceOpcoes}
                  />
                </div> :
                <FormControl
                    className="input-message-field"
                    aria-label="Mensagens"
                    ref={refInputMenssage}
                    value={mensagem}
                    placeholder="Mensagem"
                    as="textarea"
                    autoFocus={true}
                    rows={1}
                    onChange={(e) => {
                        if (e.target.value.length <= limiteCaracteresNotaInterna?.valor) {
                            atualizarQuantidadeLinhasTextarea();
                            setMensagem(e.target.value);
                        }
                    }}          
                    onFocus={()=>{
                        if(refInputSection.current){
                            if(isTablet){
                                refInputSection.current.style.paddingBottom = "10px";
                            }
                            else if(isMobile){
                                refInputSection.current.style.paddingBottom = "20px";
                            }
                        }
                    }}
                    onBlur={()=>{
                        if(isMobile || isTablet){
                            if(refInputSection.current){
                                refInputSection.current.style.paddingBottom = "0";
                            }
                        }
                    }}   
                /> }

              {!editorTexto &&  <Button
                  data-tip="Enviar mensagem"
                  className="btn-send"
                  onClick={() => {
                      onSubmit();
                  }}
              >
                  <Icon icon={sendOutlined} color="white" />
              </Button>}
            </div>

            {editorTexto && 
            <span className="group-options-editor">
                {btnOpenEditor}
                <DropdownOptions className={'dropdown-button-editor'} tamanho="lg"/>
                <Button
                    data-tip="Enviar mensagem"
                    className="editor-enviar-button"
                    onClick={() => {
                        onSubmit();
                    }}
                >
                    Enviar <Icon icon={sendOutlined} color="white" />
                </Button>
            </span>}

            <ModalDommus
                titulo="Imagens & Anexos"
                size="lg"
                open={openModalUpload}
                close={() => {
                    setOpenModalUpload(false);
                }}
            >
                <Container fluid>
                    <Tab.Container defaultActiveKey="direto">
                        <Row>
                            <Col xs={12} md={12}>
                                <Nav fill variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="direto">
                                            {(isBrowser && "Envio ") || ""}
                                            Direto
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="imagens">
                                            {(isBrowser && "Galeria de ") || ""}
                                            Imagens
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="anexos">
                                            {(isBrowser && "Galeria de ") || ""}
                                            Arquivos
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col xs={12} md={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="direto">
                                        <div className="px-3 py-3 border rounded">
                                            <Upload
                                                mensagem={mensagemAnexos}
                                                setMensagemInput={setMensagemAnexos}
                                                enviarImagem={enviarArquivos}
                                                setOpenModalUpload={
                                                    setOpenModalUpload
                                                }
                                            />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="imagens">
                                        <BrowserView className="px-3 py-3 border rounded">
                                            <Galeria
                                                tipo="imagem"
                                                definirEstadoModal={
                                                    setOpenModalUpload
                                                }
                                                enviarArquivos={enviarArquivos}
                                                mensagem={mensagemAnexos}
                                                setMensagem={setMensagemAnexos}
                                            />
                                        </BrowserView>
                                        <MobileView>
                                            <Galeria
                                                tipo="imagem"
                                                definirEstadoModal={
                                                    setOpenModalUpload
                                                }
                                                enviarArquivos={enviarArquivos}
                                                mensagem={mensagemAnexos}
                                                setMensagem={setMensagemAnexos}
                                            />
                                        </MobileView>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="anexos">
                                        <BrowserView className="px-3 py-3 border rounded">
                                            <Galeria
                                                tipo="anexo"
                                                definirEstadoModal={
                                                    setOpenModalUpload
                                                }
                                                enviarArquivos={enviarArquivos}
                                                mensagem={mensagemAnexos}
                                                setMensagem={setMensagemAnexos}
                                            />
                                        </BrowserView>
                                        <MobileView>
                                            <Galeria
                                                tipo="anexo"
                                                definirEstadoModal={
                                                    setOpenModalUpload
                                                }
                                                enviarArquivos={enviarArquivos}
                                                mensagem={mensagemAnexos}
                                                setMensagem={setMensagemAnexos}
                                            />
                                        </MobileView>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </ModalDommus>
        </>
    );
}
