import React, { createContext, ReactElement, useState, Dispatch, SetStateAction } from "react";
import { Edge, Node } from "react-flow-renderer";
import { FluxoEtapaType } from "../../types/FluxoEtapaType";
import { NodeDataType, NodeValueType } from "./types";

interface FluxoEtapaContextProps {
  nodes: Node[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  edges: Edge[];
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  listaFluxos: FluxoEtapaType[];
  setListaFluxos: Dispatch<SetStateAction<FluxoEtapaType[]>>;
  fluxoSelecionado: FluxoEtapaType;
  setFluxoSelecionado: Dispatch<SetStateAction<FluxoEtapaType>>;
  nodeSelecionado: NodeDataType;
  setNodeSelecionado: Dispatch<SetStateAction<NodeDataType>>;
  editNode: boolean;
  setEditNode: Dispatch<SetStateAction<boolean>>;
  handleAtualizarNode: (nodeId:string, value:NodeValueType) => void;
  handleAdicionarFluxo: (fluxo:FluxoEtapaType) => void;
  handleAtualizarFluxo: (idFluxo:number, fluxo:FluxoEtapaType) => void;
  handleRemoveFluxo: (idFluxo:number) => void;
}

export const FluxoEtapaContext = createContext({} as FluxoEtapaContextProps);

export function FluxoEtapaProvider({ children }: { children: ReactElement }) {
  const [listaFluxos, setListaFluxos] = useState<FluxoEtapaType[]>([] as FluxoEtapaType[])
  const [fluxoSelecionado, setFluxoSelecionado] = useState<FluxoEtapaType>({} as FluxoEtapaType);
  const [nodes, setNodes] = useState<Node[]>([] as Node[]);
  const [edges, setEdges] = useState<Edge[]>([] as Edge[]);
  const [nodeSelecionado, setNodeSelecionado] = useState<any>({} as any)
  const [editNode, setEditNode] = useState(false);

  const handleAtualizarNode = (nodeId:string, value:NodeValueType) => {
    setNodes((current) =>{
      return current.map((item)=>{
        if(item.id === nodeId){
          item.data.value = value;
        }
        return item;
      })
    })
  }

  const handleAdicionarFluxo = (fluxo:FluxoEtapaType)=>{
    setListaFluxos((current) => [...current, fluxo]);
  }

  const handleAtualizarFluxo = (idFluxo:number, fluxo:FluxoEtapaType) => {
    setListaFluxos((current) => {
      return current.map((item) => {
         if(item?.id_fluxo_etapa === idFluxo){
           return fluxo;
         }
         return item;
      })
    })
  }

  const handleRemoveFluxo = (idFluxo:number) => {
    setListaFluxos((current) => current.filter((item) => item.id_fluxo_etapa !== idFluxo));
  }

  return (
      <FluxoEtapaContext.Provider
          value={{
            nodes,
            setNodes,
            edges,
            setEdges,
            listaFluxos,
            setListaFluxos,
            fluxoSelecionado,
            setFluxoSelecionado,
            nodeSelecionado,
            setNodeSelecionado,
            editNode,
            setEditNode,
            handleAtualizarNode,
            handleAdicionarFluxo,
            handleAtualizarFluxo,
            handleRemoveFluxo,
          }}
      >
          {children}
      </FluxoEtapaContext.Provider>
  );
}
