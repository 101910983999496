import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Col, Form } from "react-bootstrap";
import "./index.css";
import DommusRequiredSpan from "../../../../../../components/DommusRequiredSpan";
import ticketService from "../../../../../../services/TicketService";
import ticketConclusaoService from "../../../../../../services/TicketConclusaoService";
import { useForm } from "react-hook-form";
import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import { errorToast } from "../../../../../../components/DommusToast";
import ValidaTipoArquivo from "../../../../../../helpers/ValidaTipoArquivo";
import Swal from "sweetalert2";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { registerLocale } from "react-datepicker";
import { config } from "dotenv";
import guService from "../../../../../../services/GuService";

export default function ConclusaoChamado({
  chamado,
  dados,
  close,
  refresh,
  setRefresh,
  atualizarChamado,
}) {
  const [listaSolucoes, setListaSolucoes] = useState([]);
  const [solucao, setSolucao] = useState("");
  const [descricao, setDescricao] = useState("");
  const [procedencia, setProcedencia] = useState("");


  const [arquivo, setArquivo] = useState("");

  const [errorSolucao, setErrorSolucao] = useState(false);
  const [errorDescricao, setErrorDescricao] = useState(false);
  const [errorProcedencia, setErrorProcedencia] = useState(false);

  const { handleSubmit, register, getValues } = useForm();

  const tipoUsuario = useMemo(()=>{
    return Number(guService.getLocalState('tipoUsuario'));
  },[])

  function verificaError() {
    if (solucao === "" || solucao === "Selecione a solucao") {
      setErrorSolucao(true);
    } else {
      setErrorSolucao(false);
    }
    if (descricao === "" && (configSelecionada?.necessario_resposta === true || configSelecionada?.necessario_resposta == null)) {
      setErrorDescricao(true);
    } else {
      setErrorDescricao(false);
    }
  }

  useEffect(() => {
    ticketConclusaoService.index().then((response) => {
      setListaSolucoes(response.data);
    });
  }, []);

  const montaArquivo = useCallback((event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (ValidaTipoArquivo.verificaTipoArquivo(file)) {
      reader.onload = function (upload) {
        let dadosArquivo = {
          conteudo: upload.target.result.split(/base64,/)[1],
          nome: file.name,
        };
        setArquivo(dadosArquivo);
      };
      reader.readAsDataURL(file);
    } else {
      errorToast.fire({
        text: "Tipo de arquivo não suportado, selecione outro ou verifique o mesmo",
      });
    }
  }, []);

  const configSelecionada = useMemo(() => {
    let config = null;
    if(solucao){
       let solucaoSelecionada = listaSolucoes.find((item) => item.id_ticket_conclusao_tipo === Number(solucao));
       config = solucaoSelecionada?.configuracao ?? null;
    }
        
    return config;
  },[solucao])

  function cadastraSolucao() {
    let enviarMensagemConclusao = getValues('enviar-mensagem-template');
 
    if (solucao === "" || solucao === "Selecione a solucao") {
      verificaError();
      return;
    }else{
      if (descricao === "" && (configSelecionada?.necessario_resposta == null || configSelecionada?.necessario_resposta)) {
        verificaError();
        return;
      } else {
        let mensagem = `${enviarMensagemConclusao ? "Será enviado uma mensagem para o cliente informando a conclusão do ticket." : "O chamado será concluído. "}Deseja prosseguir?`;
        Swal.fire({
          title: 'Conclusão de Chamado',
          text: mensagem,
          icon: 'question',
          confirmButtonText: 'Sim, enviar e finalizar ticket',
          showDenyButton: true,
          denyButtonText: 'Não prosseguir',
          confirmButtonColor: 'var(--dommus-color)'
        }).then(resposta => {
          if(resposta.isConfirmed) {
            let dadosConcusao = {
              id_ticket_conclusao_tipo: solucao,
              descricao: descricao,
              procedencia_solicitacao: procedencia,
              data_conclusao: moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
              dados_arquivo: arquivo,
              enviar_mensagem_conclusao: getValues('enviar-mensagem-template'),
            };
            trackPromise(
              ticketConclusaoService.store(dadosConcusao, chamado.id_ticket).then(
                (res) => {                  
                  close();
                  trackPromise(ticketService.update(chamado.id_ticket, dados).then(
                    (response) => {
                      Swal.fire({
                        titleText: "Chamado finalizado",
                        text: "Seu chamado foi concluído com sucesso!",
                        icon: "success",
                      }).then(() => {        
                    
                        if(atualizarChamado){
                          atualizarChamado(response.data)
                        }
                        setRefresh((current) => !current);
                      });
                    },
                    (error) => {
                      Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao salvar o chamado: " + error,
                        icon: "error",
                      });
                    }
                  ));
                },
                (error) => {
                  Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um erro ao concluir o chamado: " + error,
                    icon: "error",
                  });
                }
              )
            );
          }
        })
      }
     }
    }
  

  return (
    <div 
      style={{
        maxHeight: "80vh",
        overflowY: "auto"
      }}
    >
      <div
        className="tituloNovoChamado"
        style={{ 
          backgroundColor: "var(--dommus-color)"          
        }}
      >
        <h5 className="mb-0">Solução do Chamado</h5>
      </div>
      <Form
        className="novaIndicacaoForm mt-2"
        onSubmit={handleSubmit(cadastraSolucao)}
      >
        <Form.Group as={Col} controlId="formMotivo">
          <Form.Label>
            Solução: <DommusRequiredSpan />
          </Form.Label>
          <Form.Control
            onBlur={() => verificaError()}
            name="solucao"
            ref={register()}
            as="select"
            custom
            value={solucao}
            onChange={(event) => setSolucao(event.target.value)}
          >
            <option>Selecione a solução</option>
            {listaSolucoes.map((solucao, index) => (
              <option key={index} value={solucao.id_ticket_conclusao_tipo}>
                {solucao.nome}
              </option>
            ))}
          </Form.Control>
          {errorSolucao ? (
            <p className="campoError">Campo Obrigatório</p>
          ) : (
            <></>
          )}
        </Form.Group>

        <Form.Group as={Col} controlId="formDescricao">
          <Form.Label>
            Resposta que será encaminhada ao cliente, informado a conclusão do ticket: 
            {configSelecionada?.necessario_resposta === false ? <></> :  <DommusRequiredSpan />}
          </Form.Label>
          <Form.Control
            onBlur={() => verificaError()}
            name="descricao"
            ref={register()}
            as="textarea"
            rows={10}
            value={descricao}
            onChange={(event) => setDescricao(event.target.value)}
          ></Form.Control>
          {errorDescricao ? (
            <p className="campoError">Campo Obrigatório</p>
          ) : (
            <></>
          )}
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Check 
            type={"checkbox"}
            id={`checkbox-template`}
            ref={register()}
            name="enviar-mensagem-template"
            defaultChecked={true}
            disabled={configSelecionada?.tipos_usuarios_desmarca_envio_template && configSelecionada?.tipos_usuarios_desmarca_envio_template?.includes(tipoUsuario) ? false : true}
            label={`Enviar mensagem de conclusão`}
          />
        </Form.Group>
             
        { chamado?.categoria?.tipo == 'AT' 
        && <>
        <Form.Group as={Col}>
        <FormControl>
          <Form.Label>Procedência</Form.Label>
          <RadioGroup
              row
              aria-labelledby="procedencia-row"
              name="row-radio-procedencia"
            >
            <FormControlLabel onChange={e => setProcedencia('P')} value="P" control={<Radio />} label="Procedente" />
            <FormControlLabel onChange={e => setProcedencia('I')} value="I" control={<Radio />} label="Improcedente" />
          </RadioGroup>
        </FormControl>
        {errorProcedencia ? (
            <p className="campoError">Campo Obrigatório</p>
          ) : (
            <></>
          )}
        </Form.Group>
        </>}

        <Form.Group as={Col} controlId="formNome">
          <div className="av-item select-file">
            <div className="documentacao-item-wrapper">
              <div className="form-group">
                <div>
                  <h6>Arquivo:</h6>
                </div>
                <div className="upload-wrapper">
                  <div id="documento-0" className="documento-wrapper">
                    <div className="ajax-upload-dragdrop">
                      <span>
                        <Form.Control
                          type="file"
                          accept="application/pdf"
                          className="form-control-file"
                          onChange={montaArquivo}
                        />
                      </span>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form.Group>
        <Form.Group as={Col} controlId="formSubmit">
          <button
            className="btn btn-primary"
            type="submit"
            style={{
              backgroundColor: "var(--dommus-color)",
              alignSelf: "center",
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Salvar
          </button>
        </Form.Group>
      </Form>
    </div>
  );
}
