import React, { useContext, useEffect, useMemo, useState } from "react";
import { Accordion, Card, AccordionContext, useAccordionToggle, Tab, Col, Nav, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faExclamationTriangle, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./coluna_direita.css";
import Historico from "./historico";
import usuarioService from "../../../../../services/UsuarioService";
import ProcessoService from "../../../../../services/ProcessoService";
import { trackPromise } from "react-promise-tracker";
import NovaTarefa from "../../../../../components/MenuTopo/Agenda/NovaTrefa";
import ModalDommus from "../../../../../components/Modal";
import tarefasService from "../../../../../services/TarefasService";
import { errorToast } from "../../../../../components/DommusToast";
import Swal from "sweetalert2";
import Produto from "./produto";
import Contrato from "./contrato";
import Financeiro from "./financeiro";
import { cpfMask, celularMask } from "../../../../../components/InputTextMask";
import moment from "moment";
import helper from "../../../../../helpers/format";
import { ChamadoContext } from "../../../../../components/ContextoGlobal";
import guService from "../../../../../services/GuService";
import { DommusCarouselProponente } from "../../../../../components/DommusCarouselProponente";
import ticketService from "../../../../../services/TicketService";
import { CardAtendimento } from "./CardAtendimento";
import { DadosEmpreendimentoCondominio } from "../../../../../components/DadosEmpreendimentoCondominio";
import { QuestionariosTicket } from "./QuestionariosTicket";
import { ConfiguracaoContext } from "../../../../../contexts/ConfiguracaoContext";

export function ContextAwareToggle({ children, eventKey, callback, setColor }) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;
  // setColor(!isCurrentEventKey);
  return (
    <div
      style={{
        backgroundColor: isCurrentEventKey
          ? "var(--dommus-color)"
          : "rgba(0,0,0,.03)",
        color: isCurrentEventKey ? "white" : "black",
        padding: ".75rem 1.25rem",
        marginbottom: "0",
        borderbottom: "1px solid rgba(0,0,0,.125)",
        width: "100%",
      }}
      onClick={decoratedOnClick}
      className="card-toggle"
    >
      {children}
    </div>
  );
}

export default function ColunaInformacoes({ ticket, setRecarregarDadosChamado, recarregarDadosChamado }) {
  const [pagamento, setPagamento] = useState([]);
  const [openModalCriaTarefa, setOpenModalCriaTarefa] = useState(false);
  const [events, setEvents] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [item, setItem] = useState({});
  const [financeiro, setFinanceiro] = useState(true);
  const [conciliado, setConiliado] = useState(false);
  const [proponentes, setProponetes] = useState([]);
  const [atendimentos, setAtendimentos] = useState([]);
  const [erroFluxoFinanceiro, setErroFluxoFinanceiro] = useState('')
  const { setProcessoTicketSelecionado, setDadosEmpreendimentoTicketSelecionado } = useContext(ChamadoContext)
  const {listaConfiguracoes} = useContext(ConfiguracaoContext);

  const mostrarCodigoRelacionamento = useMemo(()=>{
    const permissao = listaConfiguracoes.find((item) => item.chave === "ativar_codigo_relacionamento");
    return Number(permissao.valor);
  },[listaConfiguracoes])

  useEffect(() => {
    if(ticket.id_processo) {
      buscarDadosPagamento();
      buscarDadosProcesso();
    }
    if(
      ticket.dados_empreendimento &&
      ticket.dados_empreendimento.id_empreendimento
    ){
      setDadosEmpreendimentoTicketSelecionado(ticket.dados_empreendimento);
    }
    pegarEventos();
    buscarAtendimentos();
  }, [refresh, recarregarDadosChamado]);

  useEffect(() => {
    if(ticket.id_processo) {
      verificaInadimplencia(pagamento)
    }
  }, [pagamento]);

  useEffect(()=>{
    setProponetes((current) => current.sort((a, b) => a.posicao - b.posicao));
  },[proponentes])

  function verificaInadimplencia(pagamentos){
    let pagamentoAtrasados = pagamentos.filter(parcela => { return parcela.statusParcela == "atrasada"})
    if(pagamentoAtrasados.length > 0){
      setFinanceiro('atrasada')
    }else{
      setFinanceiro('sem_pendencias')
    }
  }
  function buscarDadosProcesso() {
    trackPromise(
      ProcessoService.buscarDadosProcesso(ticket.id_processo).then(
        (response) => {
          setProcessoTicketSelecionado(response.data);
          if(response.data.proponente1_cpf) {
            buscarProponenteCpf(response.data.proponente1_cpf);
          }
          if(response.data.proponente2_cpf) {
            buscarProponenteCpf(response.data.proponente2_cpf, 2)
          }
          if(response.data.proponente3_cpf) {
            buscarProponenteCpf(response.data.proponente3_cpf, 3)
          }
        }
      ).catch(() => {
        Swal.fire({
          title: 'Ooops...',
          text: 'Ocorreu um erro ao carregar os dados do chamado.',
          icon: 'error',
          confirmButtonText: `Recarregar`,
          showCancelButton: true,
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if(result.isConfirmed) {
            setRecarregarDadosChamado(!recarregarDadosChamado)
          }
        })
      })
    );
  }

  function pegarEventos() {
    let filtro = {
        id_ticket: ticket.id_ticket,
        inativas: 1
    };
    trackPromise(
      tarefasService.index(filtro).then(
        (response) => {
          const arrayEventos = response.data.map((event) => {
            let startTime = new Date(
              moment(event.data_inicio).format("DD/MM/YYYY HH:mm")
            );
            let endTime = new Date(
              moment(event.data_fim).format("DD/MM/YYYY HH:mm")
            );
            return {
              id: event.id_tarefa,
              id_ticket: event.ticket.id_ticket,
              repetir: event.repetir,
              title: `${event.titulo}`,
              start: event.data_inicio,
              end: event.data_fim,
              className: "event-finished",
              detalhes: event.detalhes,
              tiposTarefa: event.tipo_tarefa,
              chamado: event.ticket.assunto,
              usuarios_responsaveis: event.usuarios_responsaveis,
              departamentos_responsaveis: event.departamentos_responsaveis,
              responsaveis_execucao_nomes: event.ticket.responsaveis_execucao_nomes,
              ativo: event.ativo
            };
          }).reverse();
          setEvents(arrayEventos);
        },
        (error) => {
          errorToast.fire({ text: `Ocorreu um erro ao : ${error}` });
          Swal.fire({
            title: 'Ooops...',
            text: 'Ocorreu um erro ao carregar os dados do chamado.',
            icon: 'error',
            confirmButtonText: `Recarregar`,
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if(result.isConfirmed) {
              setRecarregarDadosChamado(!recarregarDadosChamado)
            }
          })
        }
      )
    );
  }

  function buscarProponenteCpf(cpf, nProponente=1) {
    trackPromise(
      usuarioService
        .buscarProponenteCpf(ticket?.id_processo, cpf)
        .then((response) => {
          const dados = response.data;
          dados.nProponente = nProponente;
          const proponente = {
            cpf: cpfMask(
              dados.proponente["proponente" + dados.nProponente + "_cpf"]
            ),
            nome: dados.proponente["proponente" + dados.nProponente + "_nome"],
            genero:
              dados.proponente["proponente" + dados.nProponente + "_genero"],
            nascimento:
              dados.proponente[
                "proponente" + dados.nProponente + "_data_nascimento"
              ],

            tipoDeDocumento:
              dados.proponente[
                "proponente" + dados.nProponente + "_tipo_documento"
              ],
            numeroDoDocumento:
              dados.proponente[
                "proponente" + dados.nProponente + "_numero_documento"
              ],
            orgaoEmissor:
              dados.proponente[
                "proponente" + dados.nProponente + "_orgao_emissor"
              ],
            dataEmissao:
              dados.proponente[
                "proponente" + dados.nProponente + "_data_emissao"
              ],

            telefone1: celularMask(
              dados.proponente[
                "proponente" + dados.nProponente + "_telefone_residencial"
              ]
            ),
            telefone2: celularMask(
              dados.proponente[
                "proponente" + dados.nProponente + "_telefone_celular"
              ]
            ),
            email:
              dados.proponente["proponente" + dados.nProponente + "_email"] !=
              undefined
                ? dados.proponente[
                    "proponente" + dados.nProponente + "_email"
                  ].trim()
                : dados.proponente["proponente" + dados.nProponente + "_email"],
            endereco: {
              cep: dados.proponente[
                "proponente" + dados.nProponente + "_endereco_cep"
              ],
              tipo: dados.proponente[
                "proponente" + dados.nProponente + "_tipo_logradouro"
              ],
              logradouro:
                dados.proponente[
                  "proponente" + dados.nProponente + "_logradouro"
                ],
              numero:
                dados.proponente["proponente" + dados.nProponente + "_numero"],
              complemento:
                dados.proponente[
                  "proponente" + dados.nProponente + "_complemento"
                ],
              bairro:
                dados.proponente["proponente" + dados.nProponente + "_bairro"],
              cidade:
                dados.proponente["proponente" + dados.nProponente + "_cidade"],
              uf: dados.proponente[
                "proponente" + dados.nProponente + "_endereco_uf"
              ],
            },
          }
          setProponetes((current)=>[...current, {
            dados: proponente,
            arquivos: dados.proponente.arquivos,
            posicao: nProponente
          }]);
        }).catch(() => {
          Swal.fire({
            title: 'Ooops...',
            text: 'Ocorreu um erro ao carregar os dados do chamado.',
            icon: 'error',
            confirmButtonText: `Recarregar`,
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if(result.isConfirmed) {
              setRecarregarDadosChamado(!recarregarDadosChamado)
            }
          })
        })
    );
  }

  function createData(linha) {
    return { linha };
  }

  function deletaTarefa(id) {
    Swal.fire({
      titleText: `Excluir tarefa?`,
      text: "Tem certeza que deseja excluir a tarefa?",
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-secondary mr-3",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        trackPromise(
          tarefasService.deletar(id).then(
            (res) => {
              Swal.fire({
                titleText: "Sucesso!",
                text: `A tarefa foi excluida com sucesso!`,
                icon: "success",
              });
              setRefresh(!refresh);
            },
            (error) => {
              Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao excluir a tarefa: " + error,
                icon: "error",
              });
            }
          )
        );
      } else {
        return;
      }
    });
  }

  function buscarDadosPagamento() {
    usuarioService
      .buscarFluxoPagamento(ticket.id_processo, true, true)
      .then((response) => {
        if(response?.data?.erro) {
          setErroFluxoFinanceiro(`${response.data.erro}. ${response.data?.motivo && `Motivo: ${response.data.motivo}` || ''}`)
        } else {
          const linhas = Object.values(response.data.fluxoPagamento);
          setPagamento(linhas);
          setConiliado(true);
        }
      }).catch(() => {
        Swal.fire({
          title: 'Ooops...',
          text: 'Ocorreu um erro ao carregar os dados do chamado.',
          icon: 'error',
          confirmButtonText: `Recarregar`,
          showCancelButton: true,
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if(result.isConfirmed) {
            setRecarregarDadosChamado(!recarregarDadosChamado)
          }
        })
      })
  }

  function montarTicketsSemelhantes(ticketSemelhante, index){
    return (
      <div key={index}
            className="ticket-semelhante"
            onClick={()=>{
                window.open(`${window.location.origin}/?idChamado=${btoa(String(ticketSemelhante.id_ticket))}`, '_blank', 'noreferrer');
            }}
      >
        <div className="row-section">
          <div>
            <strong> {`${ticketSemelhante.sigla}-${ticketSemelhante.id_ticket.toString().padStart(5, '0')} `} </strong>
            {" - "}
            <strong>{ticketSemelhante.categoria}</strong>
          </div>
          <span>
            {ticketSemelhante.data_cadastro && <>
              {moment(ticketSemelhante.data_cadastro).format("DD/MM/YYYY")} {" às "}
              {moment(ticketSemelhante.data_cadastro).format("HH:mm")}
            </> || <></>}
          </span>
        </div>
        <div className="row-section">
          <span>{ticketSemelhante.motivo}</span>
          <span className="badge-status" style={{background: ticketSemelhante.status_cor ?? "#f8f8f8"}}>{ticketSemelhante.status}</span>
        </div>
      </div>
    )
  }

  function buscarAtendimentos(){
    trackPromise(ticketService.buscaAtendimentoPorTicket(ticket.id_ticket)).then((response)=>{
      setAtendimentos(response.data);
    }).catch((erro)=>{
      Swal.fire(
        'Ooops... Ocorreu um erro ao carregar os atendimentos.',
        '',
        'error'
    )
    })
  }

  return (
    <>
      <div id="direita">
        {financeiro == "atrasada" && conciliado && (
          <Alert key={"warning"} variant="warning" className="alerta-inadimplencia">
            Parece haver uma pendência financeira nesse processo. Verifique na aba "Financeiro" abaixo.
          </Alert>
        )}
        <Tab.Container id="left-tabs" defaultActiveKey="dados_cliente">
          <div className="content px-0">
            <div className="header">
              <Nav variant="pills" className="menu_direita">
                <Col>
                  <Nav.Item>
                    <Nav.Link eventKey="dados_cliente">{ticket.tipo_chamado == 'U' && 'Cliente' || 'Empreendimento'}</Nav.Link>
                  </Nav.Item>
                </Col>
                <Col>
                  <Nav.Item>
                    <Nav.Link eventKey="historico">Histórico</Nav.Link>
                  </Nav.Item>
                </Col>
                <Col>
                  <Nav.Item>
                    <Nav.Link eventKey="tarefas">Tarefas</Nav.Link>
                  </Nav.Item>
                </Col>
                <Col>
                  <Nav.Item>
                    <Nav.Link eventKey="questionarios">Questionários</Nav.Link>
                  </Nav.Item>
                </Col>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="dados_cliente">
                <div className="processo-section" onClick={()=>{
                  if(ticket.tipo_chamado == 'U' && ticket.id_processo) {
                    window.open(guService.getLocalState("linkVendas") +  "index_ui.php?mgr=MQ==&ui=NjM=&id_processo="  + ticket?.id_processo);
                  }
                }}>
                  <span>{ticket.tipo_chamado == 'U' ?
                    (ticket?.id_processo ?  `[#${ticket?.id_processo.toString().padStart(5, '0')}]` : "Processo não vinculado")
                    : ticket.tipo_chamado === "SR" && !ticket.id_processo ? "Processo não vinculado - " : ticket.id_processo ? `[#${ticket?.id_processo.toString().padStart(5, '0')}]` : 'Área Comum'}</span>
                  {ticket?.dados_empreendimento?.descricao_unidade &&
                    <span>{ticket?.dados_empreendimento?.descricao_unidade}</span>
                  }
                  {ticket?.dados_empreendimento?.nome_empreendimento &&
                    <span>{ticket?.dados_empreendimento?.nome_empreendimento} </span>
                  }
                  {ticket?.proponentes?.proponente1 ?
                    <span>{`${ticket?.proponentes?.proponente1} ${ticket?.proponentes?.proponente1_cpf ? `(${cpfMask(ticket?.proponentes?.proponente1_cpf)})` : ''}`}</span>
                    : <span>{`${ticket?.dados_empreendimento?.nome_proponente} ${ticket?.dados_empreendimento?.cpf_proponente ? `(${cpfMask(ticket?.dados_empreendimento?.cpf_proponente)})` : ''}`}</span>
                  }
                  {ticket?.codigo_relacionamento && mostrarCodigoRelacionamento ? 
                    <span> - (CR:{ticket?.codigo_relacionamento})</span>
                    : <></>
                  }
                </div>
                <Accordion className="accordion" defaultActiveKey="info">
                  <Card>
                    <div className="accordion_iten">
                      <ContextAwareToggle
                        as={Card.Header}
                        className="accordion_iten"
                        eventKey="info"
                      >
                        {(!proponentes[0] && <OverlayTrigger overlay={<Tooltip>Dados do proponente incompletos!</Tooltip>}>
                          <FontAwesomeIcon icon={faExclamationTriangle} color="var(--warning)" size="xl" className="mr-2" style={{ cursor: 'pointer' }}/>
                        </OverlayTrigger>) || <></>} Informações Cadastrais
                      </ContextAwareToggle>
                    </div>
                    <Accordion.Collapse eventKey="info">
                      {['U', 'SR'].includes(ticket.tipo_chamado) &&
                        <DommusCarouselProponente proponentes={proponentes.length ? proponentes : ticket?.dados_empreendimento}/> ||
                        <DadosEmpreendimentoCondominio idEmpreendimento={ticket.dados_empreendimento?.id_empreendimento} idCondominio={ticket.id_condominio}/>}
                    </Accordion.Collapse>
                  </Card>
                  {ticket.tipo_chamado == 'U' && (<>
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle
                          as={Card.Header}
                          className="accordion_iten"
                          eventKey="Produto"
                        >
                          Informações do Produto
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="Produto">
                        <Produto />
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} eventKey="Contrato">
                          Detalhes da Venda
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="Contrato">
                        <Contrato />
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle
                          as={Card.Header}
                          eventKey="Financeiro"
                        >
                          Extrato Financeiro
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="Financeiro">
                        <Financeiro financeiro={pagamento} erro={erroFluxoFinanceiro}/>
                      </Accordion.Collapse>
                    </Card>
                  </>)}
                </Accordion>
              </Tab.Pane>
              <Tab.Pane eventKey="historico">
                <Accordion defaultActiveKey="2">
                  <Card>
                    <div className="accordion_iten">
                      <ContextAwareToggle as={Card.Header} eventKey="0">
                        Outros tickets
                      </ContextAwareToggle>
                    </div>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div className="tickets-semelhantes-section">
                            {ticket?.outros_tickets?.length ?
                              ticket?.outros_tickets?.map((outroTicket, index)=>{
                                return montarTicketsSemelhantes(outroTicket, index)
                              })
                              :
                              <Alert variant="warning" className="mb-0 text-center">
                                Nenhum outro chamado semelhante.
                              </Alert>
                            }
                          </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <div className="accordion_iten">
                      <ContextAwareToggle as={Card.Header} eventKey="1">
                        Atendimentos
                      </ContextAwareToggle>
                    </div>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        {atendimentos && atendimentos.length ?
                          <Accordion>
                          {atendimentos.map((atendimento, index)=>{
                            return <CardAtendimento key={index} atendimento={atendimento} index={index}/>
                          })}
                          </Accordion>
                          :
                          <Alert variant="warning">Não possui nenhum atendimento ativo!</Alert>
                        }
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <div className="accordion_iten">
                      <ContextAwareToggle
                        as={Card.Header}
                        className="accordion_iten"
                        eventKey="2"
                      >
                        Histórico do Cliente
                      </ContextAwareToggle>
                    </div>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <Historico idTicket={ticket.id_ticket} recarregarDadosChamado={recarregarDadosChamado} setRecarregarDadosChamado={setRecarregarDadosChamado}/>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab.Pane>
              <Tab.Pane eventKey="tarefas">
                <Accordion defaultActiveKey="0">
                  <Card>
                    <div className="accordion_iten">
                      <ContextAwareToggle
                        as={Card.Header}
                        className="accordion_iten"
                        eventKey="0"
                      >
                        Criar nova tarefa
                      </ContextAwareToggle>
                    </div>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setItem(null);
                            setOpenModalCriaTarefa(true);
                          }}
                          style={{
                            backgroundColor: "var(--dommus-color)",
                            alignSelf: "center",
                            display: "flex",
                          }}
                        >
                          Criar tarefa
                        </button>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {events.map((item, index) => {
                    return (
                      <Card key={index}>
                        <div
                          className="accordion_iten"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <div
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              marginRight: 5,
                            }}
                          >
                            <input
                              style={{
                                marginLeft: 5,
                              }}
                              type="checkbox"
                              label="Check me out"
                            />
                          </div>
                          <div style={{ width: "100%", textDecoration: item.ativo ? 'none' : 'line-through' }}>
                            <ContextAwareToggle
                              as={Card.Header}
                              eventKey={index + 1}
                            >
                              {item.title.length > 17
                                ? `${item.ativo ? '' : '(INATIVA)'} ${item.title.substr(0, 17)}... - `
                                : `${item.ativo ? '' : '(INATIVA)'} ${item.title} - `}
                              {moment(item.start).format("DD/MM/YYYY HH:mm")}
                            </ContextAwareToggle>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey={index + 1}>
                          <Card.Body>
                            <div className="boxTarefas">
                              <b> Título:</b> {item.title}
                            </div>
                            <div className="separador" />
                            <div className="boxTarefas">
                              <b>Tipo:</b> {item.tiposTarefa.descricao}
                            </div>
                            <div className="separador" />
                            <div className="boxTarefas">
                              <b>Chamado:</b>
                              {" SS-"}
                              {helper.zeroPad(item.id_ticket, 5)}{" "}
                              {item.chamado}
                            </div>
                            <div className="separador" />
                            <div className="boxTarefas">
                              <b>Responsaveis:</b> {"Esperando back"}
                            </div>
                            <div className="separador" />
                            <div className="boxTarefas">
                              <b>Detalhes:</b> {item.detalhes}
                            </div>
                            <div className="separador" />
                            <div className="boxTarefas">
                              <b>Durante:</b> {item.repetir} semana(s)
                            </div>
                            <div className="separador" />
                            <div
                              style={{
                                justifyContent: "space-between",
                                display: "flex",
                                alignItems: "center",
                                marginTop: 15,
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                color="#444"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setItem(item);
                                  setOpenModalCriaTarefa(true);
                                }}
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                color="#f44336"
                                onClick={() => {
                                  deletaTarefa(item.id);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    );
                  })}
                </Accordion>
              </Tab.Pane>
              <Tab.Pane eventKey="questionarios" className="questionarios-ticket-section">
                <QuestionariosTicket ticket={ticket}/>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
      <ModalDommus
        className="modalFiltros"
        open={openModalCriaTarefa}
        close={() => {
          setOpenModalCriaTarefa(false);
        }}
        titulo={"Criar tarefa"}
      >
        <NovaTarefa
          cor="var(--dommus-color)"
          setEvents={setEvents}
          close={() => {
            setOpenModalCriaTarefa(false);
          }}
          disabled={false}
          chamadoSelecionado={ticket}
          disabledChamado={true}
          props={item}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      </ModalDommus>
    </>
  );
}
