import React, { useState, useCallback } from 'react';
import { Card, CardGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import bxsXCircle from '@iconify-icons/bx/bxs-x-circle';
import AnexoIcone from "./AnexoIcone";
import './styles.css';
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

export default function Anexos({ listaAnexos, setListaAnexos, filesTypes }) {
    const extensaoImagem = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'ico', 'jfif'];
    const [lista, setLista] = useState([]);

    const removeAnexo = useCallback(
        (idArquivo) => {
            const novaLista = [...listaAnexos];
            novaLista.splice(idArquivo, 1);
            setListaAnexos(novaLista);
            const novaListaFiles = [...lista];
            novaListaFiles.splice(idArquivo, 1);
            setLista(novaListaFiles);
        },
        [listaAnexos, setListaAnexos, lista]
    );

    const handleVerifyType = (file) => {
        const extensao = file.name.split('.').pop();
        if (extensaoImagem.includes(extensao)) {
            return true;
        } else {
            return extensao
        }
    };

    async function onDrop(acceptedFile) {        
        if(acceptedFile){
            let arquivoUltrapassou = false;

            acceptedFile.forEach(file => {
                if( Math.round((file.size / 1024)) > 15000){
                    arquivoUltrapassou = true;
                    return;
                }
            });            

            if(arquivoUltrapassou){
                Swal.fire({
                    titleText: "Ooops...",
                    text: "Arquivo com tamanho não suportado. O máximo é de 15Mb",
                    icon: "warning",
                });
            }else{
                let listTemp = [...lista, ...acceptedFile];
                setLista(listTemp);
                setListaAnexos(listTemp);
            }
        }        
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return <>
        <div {...getRootProps()} className="upload-area">
            <input {...getInputProps()} accept={filesTypes}/>
            <p>Arraste ou clique para carregar um arquivo. Máximo 5Mb</p>
        </div>
        <div className='previews-itens'>
            {
                lista && lista.map((file, index) => (          
                    <div className='item-card' key={index}>
                        <div className='preview-item'>
                            {handleVerifyType(file) === true
                                ? (<>
                                    < img className="img_upload" src={URL.createObjectURL(file)} alt={file.name} />
                                </>
                                )
                                :
                                <AnexoIcone anexo={handleVerifyType(file)} style={{ fontSize: "10rem" }} />
                            }
                        </div>                        
                        <div className='header-item-card'>
                            <OverlayTrigger
                                key={index}
                                placement="top"
                                overlay={
                                    <Tooltip id={String(index)}>
                                        {file ? file.name : " "}
                                    </Tooltip>
                                }
                            >
                                <span className='item-name' data-tip={file?.name ?? ""}>
                                    {file ? file.name : " "}
                                </span>                                
                            </OverlayTrigger>
                            <span className='btn-remove-item' onClick={() => removeAnexo(index)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </span>
                        </div>
                    </div>
                ))
            }
        </div>
    </>
}
