import React, { Component, useEffect } from "react";
import "../MenuLateral/index.css";
import {
  faStream,
  faPowerOff,
  faCog,
  faHeadset,
  faArrowLeft,
  faTicketAlt,
  faCommentAlt,
  faHeadphonesAlt,
  faEnvelope,  
  faClipboardList,
  faCalendar,
  faCheckSquare,
  faCheckDouble,
  faList,
  faUserCog,
  faTasks,
  faIndent,
  faUsers,
  faPhoneAlt,
  faLayerGroup,
  faTh,
  faChartLine,
  faUserPlus,
  faFileAlt,
  faProjectDiagram
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import playlistPlus from "@iconify/icons-mdi/playlist-plus";
import ReactTooltip from "react-tooltip";
import {
  faWhatsapp,
  faFacebookSquare,
  faLinkedin,
  faChrome,
  faAccessibleIcon,
} from "@fortawesome/free-brands-svg-icons";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import guService from "../../services/GuService";
import filaService from "../../services/FilaService";
import calendarSolidBadged from "@iconify-icons/clarity/calendar-solid-badged";

export default class MenuTopo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openAtendimento: false,
      openConfiguracoes: false,
      openFila: false,
      openRelatorios: false,
      listaFila: [],
    };
    this.mudarEstado = this.mudarEstado.bind(this);
    this.abrirAtendimento = this.abrirAtendimento.bind(this);
    this.abrirConfiguracoes = this.abrirConfiguracoes.bind(this);
    this.abrirFila = this.abrirFila.bind(this);
    this.abrirRelatorios = this.abrirRelatorios.bind(this);
    this.fechaDrawers = this.fechaDrawers.bind(this);
  }

  mudarEstado(e) {
    e.preventDefault();
    const novoOpen = !this.state.open;
    this.setState({ open: novoOpen });
  }

  fechaDrawers(e) {
    e.preventDefault();
    this.setState({
      openAtendimento: false,
      openConfiguracoes: false,
      openFila: false,
      openRelatorios: false
    })
  }

  abrirRelatorios(e) {
    e.preventDefault();
    const novoOpenRelatorio = !this.state.openRelatorios;
    this.setState({ 
      openRelatorios: novoOpenRelatorio,
      openAtendimento: false,
      openConfiguracoes: false,
      openFila: false
    });
  }
  abrirAtendimento(e) {
    e.preventDefault();
    const novoOpenAtendimento = !this.state.openAtendimento;
    this.setState({ 
      openAtendimento: novoOpenAtendimento ,
      openConfiguracoes: false,
      openFila: false,
      openRelatorios: false,
    });
  }

  abrirConfiguracoes(e) {
    e.preventDefault();
    const novoOpenConfiguracoes = !this.state.openConfiguracoes;
    this.setState({ 
      openConfiguracoes: novoOpenConfiguracoes,
      openAtendimento: false,
      openFila: false,
      openRelatorios: false,
    });
  }

  abrirFila(e) {
    e.preventDefault();
    const novoOpenFila = !this.state.openFila;
    this.setState({ 
      openFila: novoOpenFila,
      openConfiguracoes: false,
      openAtendimento: false,
      openRelatorios: false,
    });
    filaService.index().then((res) => {
      this.setState({ listaFila: res.data });
    });
  }

  logout(e) {
    guService.logout();
    window.location.reload(false);
  }

  render() {
    const classeAsideSidebar = "sidebar" + (this.state.open ? "" : " hide");
    const classeSidebarWrapper =
      "sidebar-wrapper" + (this.state.open ? "" : " is-closed");
    const classeAtendimento =
      "suporte is-menu " + (this.state.openAtendimento ? " open" : "");
    const classeConfiguracoes =
      "suporte is-menu " + (this.state.openConfiguracoes ? " open" : "");
    const classeRelatorios =
      "suporte is-menu " + (this.state.openRelatorios ? " open" : "");
    const classeFila =
      "suporte is-menu " + (this.state.openFila ? " open" : "");
    const telas = guService.getLocalState("telas");
    const modulos = guService.getLocalState("modulos");

    return (
      <>
        <aside className={classeAsideSidebar}>
          {!this.state.open ? <ReactTooltip /> : <></>}
          <div className={classeSidebarWrapper}>
            <div className="sidebar-title">
              <span>Menu</span>
              <a href="#" data-tip="Menu" onClick={this.mudarEstado}>
                <FontAwesomeIcon icon={faStream} />
              </a>
            </div>
            <div className="siderbar-holder">
              <div className="menu-structure">
                <ul>
                  {telas.includes("portal_cliente_atendente") && (
                    <li onClick={this.fechaDrawers} data-tip="Tickets">
                      <Link to="/">
                        <FontAwesomeIcon
                          color="#F44336"
                          icon={faClipboardList}
                        />
                        <p>Tickets</p>
                      </Link>
                    </li>
                  )}
                  {telas.includes("portal_cliente_atendente") && (
                    <li data-tip="Filas de tickets">
                      <a
                        onClick={this.abrirFila}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon
                          color="#FAFF00"
                          icon={faList}
                        />
                        <p>Filas de tickets</p>
                      </a>
                    </li>
                  )}
                  {telas.includes("dashboards") && (
                    <li data-tip="Dashboards">
                      <Link to='/dashboards'>
                        <FontAwesomeIcon color="var(--danger)" icon={faChartLine}/>
                        <p>Dashboards</p>
                      </Link>
                    </li>
                  )}
                  {telas.includes("portal_cliente_atendente") && (
                    <li data-tip="Criar fila de chamados personalizada" onClick={this.fechaDrawers}>
                      <Link to="/criar">
                        <Icon
                          icon={playlistPlus}
                          color="#FF67E7"
                          style={{
                            fontSize: 25,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                        <p>Criar Fila</p>
                      </Link>
                    </li>
                  )}
                  {telas.includes("portal_cliente_atendente") && (
                    <li onClick={this.fechaDrawers} data-tip="Eventos e Comunicados">
                      <Link to="/eventos">
                        <FontAwesomeIcon
                          color="green"
                          icon={faCalendar}
                        />
                        <p>Eventos e Comunicados</p>
                      </Link>
                    </li>
                  )}
                  {telas.includes("portal_cliente_atendente") && (
                    <li onClick={this.fechaDrawers} data-tip="Aprovar solicitações">
                      <Link to="/aprovacao">
                        <FontAwesomeIcon
                          color="orange"
                          icon={faCheckSquare}
                        />
                        <p>Aprovar solicitações</p>
                      </Link>
                    </li>
                  )}

                  {telas.includes("portal_cliente_atendente") && (
                    <li onClick={this.fechaDrawers} data-tip="Solitações de Novo Acesso">
                    <Link to="/solicitacao-novo-acesso">
                      <FontAwesomeIcon
                        color="green"
                        icon={faUsers}
                      />
                      <p>Solicitações de Novo Acesso</p>
                    </Link>
                  </li>
                  )

                  }
                  {telas.includes("portal_cliente_atendente") && (
                    <li onClick={this.fechaDrawers} data-tip="Aprovar Inscrições" >
                      <Link to="/inscricoes">
                        <FontAwesomeIcon
                          color="#BFA2DB"
                          icon={faCheckDouble}                         
                        />
                        <p>Aprovar Inscrições</p>
                      </Link>
                    </li>
                  )}
                  {telas.includes("portal_cliente_atendente") && (
                    <li onClick={this.fechaDrawers} data-tip="Indicações">
                      <Link to="/indicacoes">
                        <FontAwesomeIcon
                          color="green"
                          icon={faUserPlus}
                        />
                        <p>Indicações</p>
                      </Link>
                    </li>
                  )}
                  {telas.includes("portal_cliente_atendente") && (
                      <li onClick={this.fechaDrawers} data-tip="Agenda do Usuário">
                        <Link to="/agenda-do-usuario">
                          <Icon icon={calendarSolidBadged} color="#FF865E" width={20}/>
                          <p>Agenda do Usuário</p>
                        </Link>
                      </li>
                  )}

                  {JSON.parse(modulos).includes(6) && (
                    <li data-tip="Central de atendimento">
                      <a
                        href="https://atendimento.dommus.com.br"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          color="#2F435A"
                          icon={faPhoneAlt}
                        />
                        <p>Central de atendimento</p>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="info-structure">
                <ul>
                  <li onClick={this.abrirRelatorios}>
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faTh}
                        color="#868AA8"
                        data-tip="Relatórios"
                      />
                      <p>Relatórios</p>
                    </a>
                  </li>
                  <li onClick={this.abrirConfiguracoes} data-tip="Configurações">
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faCog}
                        color="var(--dommus-color-2)"
                      />
                      <p>Configurações</p>
                    </a>
                  </li>
                  <li onClick={this.abrirAtendimento} data-tip="Atendimento">
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faHeadset}
                        color="#868aa8"
                      />
                      <p>Atendimento</p>
                    </a>
                  </li>
                  <li onClick={this.logout} data-tip="Sair">
                    <Link to="/sair">
                      <FontAwesomeIcon
                        icon={faPowerOff}
                        color="#f44336"
                      />
                      <p>Sair</p>
                    </Link>
                  </li>
                </ul>
                <div className="copy">
                  Copyright © 2021{" "}
                  <a href="https://www.linkedin.com/company/dommus-sistemas/">
                    Dommus Sistemas
                  </a>
                </div>
              </div>
            </div>
          </div>
        </aside>
        <div className="menu-sections">
          <section
            className={classeAtendimento}
            style={{ flexDirection: "row", zIndex: 10 }}
          >
            <header>
              Atendimento ao Usuário
              <div className="close-pane" onClick={this.abrirAtendimento}>
                <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
              </div>
            </header>
            <h6>Suporte:</h6>
            <ul>
              <li>
                <a
                  href="https://g3comunicacao.tomticket.com/?account=1390400P23042018093406"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faTicketAlt} color="#ffcd56" />
                  <p>Ticket</p>
                </a>
                <span>|</span>
                <a
                  href="https://g3comunicacao.tomticket.com/chat/?id=EP21725&amp;ac=1390400P23042018093406"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faCommentAlt} color="#ffcd56" />
                  <p>Chat</p>
                </a>
              </li>
            </ul>
            <h6>Telefones:</h6>
            <ul className="telefones">
              <li>
                <a href="tel:+55 31 38899776">
                  <FontAwesomeIcon icon={faHeadphonesAlt} color="#673AB7" />
                  <p>31 3889 - 9776</p>
                </a>
              </li>
              <li>
                <a href="tel:+55 31 984895755">
                  <FontAwesomeIcon icon={faWhatsapp} color="#009688" />
                  <p>31 98489 - 5755</p>
                </a>
              </li>
            </ul>
            <h6>Email:</h6>
            <ul className="email">
              <li>
                <a href="mailto:comercial@dommus.com.br">
                  <FontAwesomeIcon icon={faEnvelope} color="#E57373" />
                  <p>comercial@dommus.com.br</p>
                </a>
              </li>
            </ul>
            <h6>Redes Sociais:</h6>
            <ul className="redes-sociais">
              <li>
                <a
                  href="https://www.facebook.com/dommussistemas/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebookSquare} color="#3b5998" />
                  <p>Facebook</p>
                </a>
                <span>|</span>
                <a
                  href="https://www.linkedin.com/company/dommus-sistemas/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faLinkedin} color="#3b5998" />
                  <p>LinkedIn</p>
                </a>
              </li>
            </ul>
            <h6>Site:</h6>
            <ul className="website">
              <li>
                <a href="https://website.dommus.com.br" target="_blank">
                  <FontAwesomeIcon
                    icon={faChrome}
                    color="var(--dommus-color-2)"
                  />
                  <p>Website</p>
                </a>
              </li>
            </ul>
            <h6>V 0.0.1</h6>
          </section>
          <section
            className={classeAtendimento}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              // left: "25%",
              width: "100%",
            }}
          ></section>
          <section
            className={classeConfiguracoes}
            style={{ flexDirection: "row", zIndex: 10 }}
          >
            <header>
              Configurações
              <div className="close-pane" onClick={this.abrirConfiguracoes}>
                <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
              </div>
            </header>
            <ul>
              {telas.includes("configuracoes_gestor") && (
                <li onClick={this.abrirConfiguracoes} data-tip="Motivos">
                  <Link to="/motivos">
                    <FontAwesomeIcon
                      color="#FF4C29"
                      icon={faIndent}
                    />
                    <p>Motivos</p>
                  </Link>
                </li>
              )}
              {telas.includes("configuracoes_gestor") && (
                <li onClick={this.abrirConfiguracoes} data-tip="Tipos Tarefa">
                  <Link to="/tipos-tarefa">
                    <FontAwesomeIcon
                      color="#EFB7B7"
                      icon={faTasks}
                    />
                    <p>Tipos Tarefa</p>
                  </Link>
                </li>
              )}
              {telas.includes("configuracoes_gestor") && (
                <li onClick={this.abrirConfiguracoes} data-tip="Departamentos">
                  <Link to="/departamentos">
                    <FontAwesomeIcon
                      color="#7FC8A9"
                      icon={faUserCog}
                    />
                    <p>Departamentos</p>
                  </Link>
                </li>
              )}
              {telas.includes("configuracoes_gestor") && (
                <li onClick={this.abrirConfiguracoes} data-tip="Usuários">
                  <Link to="/usuarios">
                    <FontAwesomeIcon
                      color="#082032"
                      icon={faUsers}
                    />
                    <p>Usuários</p>
                  </Link>
                </li>
              )}
              {telas.includes("configuracoes_gestor") && (
                <li onClick={this.abrirConfiguracoes} data-tip="Tipos Ticket">
                  <Link to="/tipos-ticket">
                    <FontAwesomeIcon
                      color="#5820ba"
                      icon={faTicketAlt}
                    />
                    <p>Fluxos de Atendimento</p>
                  </Link>
                </li>
              )}
              {telas.includes("configuracoes_gestor") && (
                <li onClick={this.abrirConfiguracoes} data-tip="Templates">
                  <Link to="/templates">
                    <FontAwesomeIcon
                      color="#F55B4F"
                      icon={faLayerGroup}
                    />
                    <p>Templates</p>
                  </Link>
                </li>
              )}
              {telas.includes("configuracoes_gestor") && (
                <li onClick={this.abrirConfiguracoes} data-tip="Questionários">
                  <Link to="/questionarios">
                    <FontAwesomeIcon
                      color="#219EBC"
                      icon={faFileAlt}
                    />
                    <p>Questionários</p>
                  </Link>
                </li>
              )}
              {telas.includes("configuracoes_gestor") && (
                <li onClick={this.abrirConfiguracoes} data-tip="Fluxo de Etapas">
                  <Link to="/fluxo-etapas">
                    <FontAwesomeIcon
                      color="#fb8500"
                      icon={faProjectDiagram}
                    />
                    <p>Fluxo de Etapas</p>
                  </Link>
                </li>
              )}
            </ul>
          </section>
          <section
            className={classeConfiguracoes}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              // left: "25%",
              width: "100%",
            }}
          ></section>
          <section
            className={classeRelatorios}
            style={{ flexDirection: "row", zIndex: 10 }}
          >
            <header>
              Relatórios
              <div className="close-pane" onClick={this.abrirRelatorios}>
                <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
              </div>
            </header>
            <ul>
                <li onClick={this.abrirRelatorios} data-tip="Diversos">
                  <Link to="/relatorios/diversos">
                    <FontAwesomeIcon
                      color="#F44336"
                      icon={faChartLine}
                    />
                    <p>Diversos</p>
                  </Link>
                </li>            
            </ul>
          </section>
          <section
            className={classeRelatorios}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              // left: "25%",
              width: "100%",
            }}
          ></section>
          <section
            className={classeFila}
            style={{ flexDirection: "row", zIndex: 10 }}
          >
            <header>
              Filas
              <div className="close-pane" onClick={this.abrirFila}>
                <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
              </div>
            </header>
            <ul>
              {this.state.listaFila.map((item, index) => (
                <li key={index.toString()} onClick={this.abrirFila}>
                  <Link
                    key={index.toString()}
                    to={{
                      pathname: "/fila/" + item.id_fila_chamado,
                      state: { nome: item.nome, trava: true },
                    }}
                  >
                    <p>{item.nome}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </section>
          <section
            className={classeFila}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              // left: "25%",
              width: "100%",
            }}
          ></section>
        </div>
      </>
    );
  }
}
